import "./Why-new.scss";
import calidad from "../../assets/why/quality-reward-svgrepo-com.svg";
import garantia from "../../assets/why/secure-svgrepo-com.svg";
import asesoria from "../../assets/why/businessman-svgrepo-com.svg";
import cumplimiento from "../../assets/why/compliance-svgrepo-com.svg";
import { useEffect } from "react";
import { gsap } from "gsap";

function WhyNew() {
  //mouse circulo
  useEffect(() => {
    let whyContainer = document.getElementById("why-new-container");
    let pointerCircle = document.getElementById("pointer-circle");

    whyContainer.addEventListener("mouseenter", (event) => {
      gsap.to(pointerCircle, {
        opacity: 1,
        duration: 0.5,
        scale: 1,
      });
    });

    whyContainer.addEventListener("mouseleave", (event) => {
      gsap.to(pointerCircle, { opacity: 0, scale: 0, duration: 0.5 });
    });

    document.body.addEventListener("mousemove", (ev) => {
      pointerCircle.style.top = ev.clientY + 10 + "px";
      pointerCircle.style.left = ev.clientX + 10 + "px";
    });
  }, []);

  // informacion de la tarjeta
  function showDescription(section, section2) {
    document.querySelectorAll(".d-b").forEach((sec) => {
      sec.classList.remove("d-b-active");
    });
    document.getElementById(section).classList.add("d-b-active");
    document.querySelectorAll(".square-box").forEach((sec) => {
      sec.classList.remove("square-box-active");
    });
    document.getElementById(section2).classList.add("square-box-active");
  }

  return (
    <div>
      <div className="pointer-circle" id="pointer-circle">
        <svg width="140" height="140">
          <circle cx="70" cy="70" r="70" strokeWidth="0"></circle>
          <text fill="white" x="21" y="66">
            ¿POR QUÉ
          </text>
          <text fill="white" x="21" y="86">
            NOSOTROS?
          </text>
        </svg>
      </div>
      <div className="why-container" id="why-new-container">
        <div className="why-new-box">
          <ul className="ul-box">
            <div className="li-container">
              <img src={calidad} className="li-img" />
              <li
                className="li-box"
                onClick={() => {
                  showDescription("calidad", "quality");
                }}
              >
                {" "}
                CALIDAD
              </li>
              <div className="square-box square-box-active" id="quality"></div>
            </div>
            <div className="li-container">
              <img src={cumplimiento} className="li-img" />
              <li
                className="li-box"
                onClick={() => {
                  showDescription("cumplimiento", "compliance");
                }}
              >
                {" "}
                CUMPLIMIENTO
              </li>
              <div className="square-box" id="compliance"></div>
            </div>
            <div
              className="li-container"
              style={{ lineHeight: 1, textAlign: "start" }}
            >
              <img src={asesoria} className="li-img" />
              <li
                className="li-box"
                onClick={() => {
                  showDescription("asesoria", "advice");
                }}
              >
                <div>ASESORIA</div>
                <div>PERSONALIZADA</div>
              </li>
              <div className="square-box" id="advice"></div>
            </div>
            <div className="li-container">
              <img src={garantia} className="li-img" />
              <li
                className="li-box"
                onClick={() => {
                  showDescription("garantia", "guarante");
                }}
              >
                {" "}
                GARANTÍAS
              </li>
              <div className="square-box" id="guarante"></div>
            </div>
          </ul>
          <div className="vertical-line"></div>
          <div className="description-box">
            <div className="d-b d-b-active" id="calidad">
              <img src={calidad} className="de-img" />
              <div className="d-font">CALIDAD</div>
              <div className="d-text">
                Ofrecemos productos de alta calidad para todos los gustos.
                Utilizamos los mejores insumos para la elaboración de nuestros
                productos. Contamos con una infraestructura propia para poder
                controlar los procesos de producción.
              </div>
            </div>
            <div className="d-b" id="cumplimiento">
              <img src={cumplimiento} className="de-img" />
              <div className="d-font">CUMPLIMIENTO</div>
              <div className="d-text">
                Nuestra empresa cuenta con un proceso de producción óptimo, que
                nos permite cumplir con nuestros objetivos en los tiempos
                pactados.
              </div>
            </div>
            <div className="d-b" id="asesoria">
              <img src={asesoria} className="de-img" />
              <div className="d-font" style={{ lineHeight: 1 }}>
                ASESORÍA PERSONALIZADA
              </div>
              <div className="d-text">
                Durante todo el proceso, nuestro equipo altamente capacitado
                estará a disposición de nuestros clientes, brindando todas las
                herramientas y conocimientos necesarios para desarrollar
                cualquier proyecto.
              </div>
            </div>
            <div className="d-b" id="garantia">
              <img src={garantia} className="de-img" />
              <div className="d-font">GARANTÍAS</div>
              <div className="d-text">
                Nuestro compromiso con los clientes es ofrecer productos de alta
                calidad, utilizando los mejores materiales disponibles en el
                país. Contamos con un servicio al cliente especializado en
                resolver cualquier eventualidad que se presente.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*mobile*/}

      <div className="why-container-1">
        <div className="why-new-box-1">
          <div className="mobile-why">
            <div className="title-mobile-new">¿POR QUÉ NOSOTROS?</div>
            <img src={calidad} className="de-img" />
            <div className="d-font">CALIDAD</div>
            <div className="d-text">
              Ofrecemos productos de alta calidad para todos los gustos.
              Utilizamos los mejores insumos para la elaboración de nuestros
              productos. Contamos con una infraestructura propia para poder
              controlar los procesos de producción.
            </div>
          </div>
          <div className="mobile-why">
            <img src={cumplimiento} className="de-img" />
            <div className="d-font">CUMPLIMIENTO</div>
            <div className="d-text">
              Nuestra empresa cuenta con un proceso de producción óptimo, que
              nos permite cumplir con nuestros objetivos en los tiempos
              pactados.
            </div>
          </div>
          <div className="mobile-why">
            <img src={asesoria} className="de-img" />
            <div className="d-font" style={{ lineHeight: 1 }}>
              ASESORÍA PERSONALIZADA
            </div>
            <div className="d-text">
              Durante todo el proceso, nuestro equipo altamente capacitado
              estará a disposición de nuestros clientes, brindando todas las
              herramientas y conocimientos necesarios para desarrollar cualquier
              proyecto.
            </div>
          </div>
          <div className="mobile-why">
            <img src={garantia} className="de-img" />
            <div className="d-font">GARANTÍAS</div>
            <div className="d-text">
              Nuestro compromiso con los clientes es ofrecer productos de alta
              calidad, utilizando los mejores materiales disponibles en el país.
              Contamos con un servicio al cliente especializado en resolver
              cualquier eventualidad que se presente.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyNew;
