import { Controller, useForm } from "react-hook-form";
import "./Contacto.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useState } from "react";

function Contacto() {
  const [showModal, setShowModal] = useState(false);
  const defaultValues = {
    nombre: "",
    colegio: "",
    telefono: "",
    email: "",
    mensaje: "",
  };
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({ defaultValues });
  const enviarGuardarContacto = async (data) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/contacto`, data);
      reset(defaultValues);
      mostrarModal();
    } catch (error) {
      alert(error);
    }
  };
  const ocultarModal = () => {
    setShowModal(false);
  };
  const mostrarModal = () => {
    setShowModal(true);
  };
  return (
    <div>
      <div className="headerStyle">
        <div className="headerContact">
          <div className="contact">CONTACTO</div>
          <hr className="line line-header" />
          <p className="subHeader">Queremos crear algo lindo para ti</p>
        </div>
      </div>
      <p className="saludo">Escríbenos para tener la mejor chaqueta prom</p>
      <div className="form">
        <div className="fill">
          <form onSubmit={handleSubmit(enviarGuardarContacto)}>
            <Controller
              {...register("nombre")}
              control={control}
              rules={{ required: { value: true, message: "campo requerido" } }}
              render={({ field }) => {
                return (
                  <input
                    {...field}
                    type="text"
                    className={
                      errors.nombre && errors.nombre.message
                        ? "input-error"
                        : ""
                    }
                    placeholder="Nombre"
                  />
                );
              }}
            ></Controller>
            <span className="text-danger">
              {errors.nombre && errors.nombre.message}
            </span>
            <Controller
              {...register("colegio")}
              control={control}
              rules={{ required: { value: true, message: "campo requerido" } }}
              render={({ field }) => {
                return (
                  <input
                    {...field}
                    type="text"
                    className={
                      errors.colegio && errors.colegio.message
                        ? "input-error"
                        : ""
                    }
                    placeholder="Colegio"
                  />
                );
              }}
            ></Controller>
            <span className="text-danger">
              {errors.colegio && errors.colegio.message}
            </span>
            <Controller
              {...register("telefono")}
              control={control}
              rules={{
                required: { value: true, message: "campo requerido" },
                pattern: { value: /^\d{9}$/, message: "Teléfono inválido" },
              }}
              render={({ field }) => {
                return (
                  <input
                    {...field}
                    type="text"
                    className={
                      errors.telefono && errors.telefono.message
                        ? "input-error"
                        : ""
                    }
                    placeholder="Celular"
                  />
                );
              }}
            ></Controller>
            <span className="text-danger">
              {errors.telefono && errors.telefono.message}
            </span>
            <Controller
              {...register("email")}
              control={control}
              rules={{
                required: { value: true, message: "campo requerido" },
                pattern: {
                  value: /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/,
                  message: "correo inválido",
                },
              }}
              render={({ field }) => {
                return (
                  <input
                    {...field}
                    type="text"
                    className={
                      errors.email && errors.email.message ? "input-error" : ""
                    }
                    placeholder="Email"
                  />
                );
              }}
            ></Controller>
            <span className="text-danger">
              {errors.email && errors.email.message}
            </span>
            <Controller
              {...register("mensaje")}
              control={control}
              rules={{ required: { value: true, message: "campo requerido" } }}
              render={({ field }) => {
                return (
                  <textarea
                    {...field}
                    className={
                      errors.mensaje && errors.mensaje.message
                        ? "input-error"
                        : ""
                    }
                    cols="30"
                    rows="10"
                    placeholder="Mensaje"
                  ></textarea>
                );
              }}
            ></Controller>
            <span className="text-danger">
              {errors.mensaje && errors.mensaje.message}
            </span>
            <input
              type="submit"
              id="send"
              className="enviar"
              placeholder="Enviar"
            />
          </form>
        </div>
        <div className="info">
          <div>
            <i className="fas fa-map-marker-alt"></i>
            <span>Transversal 22bis # 61b - 67</span>
          </div>

          <div>
            <i className="fas fa-phone-alt"></i>
            <span>6015486676 - 6012804105</span>
          </div>

          <div>
            <i className="fas fa-envelope"></i>
            <span>vegaprom@hotmail.com</span>
          </div>
          <div>
            <i className="flag"></i>
            <span className="ciudad">Bogotá - Colombia</span>
          </div>
          <div>
            <hr />
          </div>
          <div className="wa">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://api.whatsapp.com/send?phone=573015653747&text=Hola. Deseo obtener más información.https://api.whatsapp.com/send?phone=573015653747&text=Hola.%20Deseo%20obtener%20m%C3%A1s%20informaci%C3%B3n."
              className="whatsApp"
            >
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
          </div>
        </div>
      </div>
      {/*modal mensaje*/}
      <Modal show={showModal} onHide={ocultarModal}>
        <Modal.Header closeButton>
          <Modal.Title>Solicitud de Contacto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tu solicitud ha sido recibida,
          <br /> te contactaremos lo más pronto posible.
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Contacto;
