import "./Sacos.scss";
import sac1 from "../../assets/productos/sacos/IMG_3017.jpg";
import sac2 from "../../assets/productos/sacos/IMG_3069.jpg";
import sac3 from "../../assets/productos/sacos/IMG_3075.jpg";
import sac4 from "../../assets/productos/sacos/IMG_3083.jpg";
import sac5 from "../../assets/productos/sacos/IMG_3084.jpg";
import sac6 from "../../assets/productos/sacos/IMG_3088.jpg";
import sac7 from "../../assets/productos/sacos/IMG_3106.jpg";
import sac8 from "../../assets/productos/sacos/sacos2/IMG_3169.jpg";
import sac9 from "../../assets/productos/sacos/sacos2/IMG_3167.jpg";
import sac10 from "../../assets/productos/sacos/sacos2/IMG_3170.jpg";
import sac11 from "../../assets/productos/sacos/sacos2/IMG_3175.jpg";
import sac12 from "../../assets/productos/sacos/sacos2/IMG_3178.jpg";
import sac13 from "../../assets/productos/sacos/sacos2/IMG_3182.jpg";
import sac14 from "../../assets/productos/sacos/sacos2/IMG_3186.jpg";
import sac15 from "../../assets/productos/sacos/sacos2/IMG_3191.jpg";
import sac16 from "../../assets/productos/sacos/sacos2/IMG_3193.jpg";
import sac17 from "../../assets/productos/sacos/sacos2/IMG_3197.jpg";
import sac18 from "../../assets/productos/sacos/sacos2/IMG_3201.jpg";
import sac19 from "../../assets/productos/sacos/sacos2/IMG_3204.jpg";
import sac20 from "../../assets/productos/sacos/sacos2/IMG_3219.jpg";
import sac21 from "../../assets/productos/sacos/sacos2/IMG_3221.jpg";
import sac22 from "../../assets/productos/sacos/sacos2/IMG_3223.jpg";
import sac23 from "../../assets/productos/sacos/sacos2/IMG_3229.jpg";
import sac24 from "../../assets/productos/sacos/sacos2/IMG_3232.jpg";
import sac25 from "../../assets/productos/sacos/sacos2/IMG_3233.jpg";
import sac26 from "../../assets/productos/sacos/sacos2/IMG_3243.jpg";
import sac27 from "../../assets/productos/sacos/sacos2/IMG_3245.jpg";
import sac28 from "../../assets/productos/sacos/sacos2/IMG_3247.jpg";
import sac29 from "../../assets/productos/sacos/sacos2/IMG_3250.jpg";
import sac30 from "../../assets/productos/sacos/sacos2/IMG_3257.jpg";
import sac31 from "../../assets/productos/sacos/sacos2/IMG_3268.jpg";
import sac32 from "../../assets/productos/sacos/sacos2/IMG_3272.jpg";
import sac33 from "../../assets/productos/sacos/sacos2/IMG_3274.jpg";
import sac34 from "../../assets/productos/sacos/sacos2/IMG_3277.jpg";
import sac35 from "../../assets/productos/sacos/sacos2/IMG_3279.jpg";
import sac36 from "../../assets/productos/sacos/sacos2/IMG_3284.jpg";
import sac37 from "../../assets/productos/sacos/sacos2/IMG_3291.jpg";
import sac38 from "../../assets/productos/sacos/sacos2/IMG_3293.jpg";
import sac39 from "../../assets/productos/sacos/sacos2/IMG_3298.jpg";

import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import HeaderNew from "../header/Header-new";

function Sacos() {
  useEffect(() => {
    var sacos = document.getElementById("sacos");
    var sacosBox = document.getElementById("box-sacos");
    gsap.registerPlugin(ScrollTrigger);
    var timeline = gsap.timeline({
      scrollTrigger: {
        trigger: document.body,
        scrub: 0.6,
        start: "top top",
        end: () => {
          return window.innerHeight / 2;
        },
      },
    });
    timeline.fromTo(
      sacos,
      { fontSize: "10vw", duration: 1, top: "50vh", yPercent: -50 },
      {
        fontSize: "3vw",
        duration: 1,
        top: "0",
        yPercent: 0,
        paddingTop: "80px",
      },
      "1"
    );

    var pics1 = document.getElementById("sacos-trigger");
    var timeline2 = gsap.timeline({
      scrollTrigger: {
        trigger: pics1,
        scrub: 0.6,
        start: "top top",
        end: "bottom bottom",
      },
    });

    gsap.utils.toArray(".sacos-pin").forEach((pics) => {
      setTimeout(() => {
        var yPercent =
          100 -
          (pics.getBoundingClientRect().height * 100) /
            pics1.getBoundingClientRect().height;
        timeline2.fromTo(pics, { yPercent: 0 }, { yPercent: yPercent }, "l");
      }, 500);
    });
  }, []);

  // click photo modal

  const [show, setShow] = useState(false);
  const [image, setImage] = useState(null);
  const handleClose = () => {
    setShow(false);
    document.getElementById("root").style.filter = "";
  };
  const handleShow = (imagen) => {
    setImage(imagen);
    setShow(true);
    document.getElementById("root").style.filter = "blur(12px)";
  };
  function backHome() {
    window.location.href = "/#producto-box";
  }

  return (
    <div>
      <div className="sacos-menu">
        <div className="send-btn-1">
          <button className="send-text-1" onClick={backHome}>
            VOLVER ATRÁS
          </button>
        </div>
      </div>

      <div className="productos-pic" id="product">
        <div className="box-sacos" id="box-sacos">
          <h1 className="logo-title" id="sacos">
            SACOS
          </h1>
        </div>
        <div className="pic-container">
          <div className="pic-box-sacos" id="sacos-trigger">
            <img
              src={sac1}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac1);
              }}
            />
            <img
              src={sac2}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac2);
              }}
            />
            <img
              src={sac3}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac3);
              }}
            />
            <img
              src={sac4}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac4);
              }}
            />
            <img
              src={sac5}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac5);
              }}
            />
            <img
              src={sac6}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac6);
              }}
            />
            <img
              src={sac7}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac7);
              }}
            />
            <img
              src={sac8}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac8);
              }}
            />
            <img
              src={sac9}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac9);
              }}
            />
            <img
              src={sac10}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac10);
              }}
            />
            <img
              src={sac11}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac11);
              }}
            />
            <img
              src={sac12}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac12);
              }}
            />
            <img
              src={sac13}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac13);
              }}
            />
          </div>
          <div className="pic-box-sacos pic-box-sacos-2 sacos-pin">
            <img
              src={sac14}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac14);
              }}
            />
            <img
              src={sac15}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac15);
              }}
            />
            <img
              src={sac16}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac16);
              }}
            />
            <img
              src={sac17}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac17);
              }}
            />
            <img
              src={sac18}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac18);
              }}
            />
            <img
              src={sac19}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac19);
              }}
            />
            <img
              src={sac20}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac20);
              }}
            />
            <img
              src={sac21}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac21);
              }}
            />
            <img
              src={sac22}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac22);
              }}
            />
            <img
              src={sac23}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac23);
              }}
            />
            <img
              src={sac24}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac24);
              }}
            />
            <img
              src={sac25}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac25);
              }}
            />
            <img
              src={sac26}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac26);
              }}
            />
          </div>
          <div className="pic-box-sacos sacos-pin">
            <img
              src={sac27}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac27);
              }}
            />
            <img
              src={sac28}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac28);
              }}
            />
            <img
              src={sac29}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac29);
              }}
            />
            <img
              src={sac30}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac30);
              }}
            />
            <img
              src={sac31}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac31);
              }}
            />
            <img
              src={sac32}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac32);
              }}
            />
            <img
              src={sac33}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac33);
              }}
            />
            <img
              src={sac34}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac34);
              }}
            />
            <img
              src={sac35}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac35);
              }}
            />
            <img
              src={sac36}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac36);
              }}
            />
            <img
              src={sac37}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac37);
              }}
            />
            <img
              src={sac38}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac38);
              }}
            />
            <img
              src={sac39}
              className="pic-sacos"
              onClick={() => {
                handleShow(sac39);
              }}
            />
          </div>
        </div>
      </div>

      {/*mobile*/}

      <div className="display-mobile-sacos">
        <div className="title-box-mobile">
          <h1 className="mobile-title-sacos">SACOS</h1>
        </div>
        <div className="pic-mobile-box">
          <img
            src={sac1}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac1);
            }}
          />
          <img
            src={sac2}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac2);
            }}
          />
          <img
            src={sac3}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac3);
            }}
          />
          <img
            src={sac4}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac4);
            }}
          />
          <img
            src={sac5}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac5);
            }}
          />
          <img
            src={sac6}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac6);
            }}
          />
          <img
            src={sac7}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac7);
            }}
          />
          <img
            src={sac8}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac8);
            }}
          />
          <img
            src={sac9}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac9);
            }}
          />
          <img
            src={sac10}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac10);
            }}
          />
          <img
            src={sac11}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac11);
            }}
          />
          <img
            src={sac12}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac12);
            }}
          />
          <img
            src={sac13}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac13);
            }}
          />
          <img
            src={sac14}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac14);
            }}
          />
          <img
            src={sac15}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac15);
            }}
          />
          <img
            src={sac16}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac16);
            }}
          />
          <img
            src={sac17}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac17);
            }}
          />
          <img
            src={sac18}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac18);
            }}
          />
          <img
            src={sac19}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac19);
            }}
          />
          <img
            src={sac20}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac20);
            }}
          />
          <img
            src={sac21}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac21);
            }}
          />
          <img
            src={sac22}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac22);
            }}
          />
          <img
            src={sac23}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac23);
            }}
          />
          <img
            src={sac24}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac24);
            }}
          />
          <img
            src={sac25}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac25);
            }}
          />
          <img
            src={sac26}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac26);
            }}
          />
          <img
            src={sac27}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac27);
            }}
          />
          <img
            src={sac28}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac28);
            }}
          />
          <img
            src={sac29}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac29);
            }}
          />
          <img
            src={sac30}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac30);
            }}
          />
          <img
            src={sac31}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac31);
            }}
          />
          <img
            src={sac32}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac32);
            }}
          />
          <img
            src={sac33}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac33);
            }}
          />
          <img
            src={sac34}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac34);
            }}
          />
          <img
            src={sac35}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac35);
            }}
          />
          <img
            src={sac36}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac36);
            }}
          />
          <img
            src={sac37}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac37);
            }}
          />
          <img
            src={sac38}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac38);
            }}
          />
          <img
            src={sac39}
            className="pic-sacos"
            onClick={() => {
              handleShow(sac39);
            }}
          />
        </div>
      </div>

      <Modal
        className="modal-sacos"
        size="xl"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Body className="modal-box-sacos">
          <img className="modal-pic" src={image} onClick={handleClose} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Sacos;
