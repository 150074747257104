import "./Construcction.scss";
import back from "../../assets/construction/background.jpg";
import backWebp from "../../assets/construction/background.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import ImageWebp from "../../shared/image-webp/ImageWepb";

function Construction() {
  return (
    <div className="construction-box">
      <ImageWebp className="back-const" src={back} srcWebp={backWebp} />
      <div className="text-const">
        <p className="font-const">PÁGINA EN CONSTRUCCIÓN</p>
        <p className="font-const">VOLVEMOS PRONTO</p>
        <p className="font-const-1">Tel. 3008179492 - 3015653747</p>
        <div className="wa">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://api.whatsapp.com/send?phone=573015653747&text=Hola. Deseo obtener más información.https://api.whatsapp.com/send?phone=573015653747&text=Hola.%20Deseo%20obtener%20m%C3%A1s%20informaci%C3%B3n."
            className="whatsApp-const"
          >
            <FontAwesomeIcon icon={faWhatsapp} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Construction;
