import "./Header-new.scss";
import logo from "../../assets/header/VEGALOGOS2022.png";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

function HeaderNew({ isHome }) {
  var navigate = useNavigate();
  useEffect(() => {
    // Get Modal
    var modal = document.getElementById("myModal");

    // Get pseudoelement to open Modal
    var btn = document.getElementById("sized");

    // Get the <span> element to close Modal
    var span = document.getElementsByClassName("close")[0];

    // When user clicks button, open Modal
    btn.onclick = function () {
      modal.style.display = "block";
    };

    // When user clicks Close (x), close Modal
    span.onclick = function () {
      modal.style.display = "none";
    };
  }, []);
  function closeMenu(section) {
    // cerrar el modal cuando le dan click

    var myModal = document.getElementById("myModal");
    myModal.style.display = "none";

    // baja a la seccion que se quiere cuando le dan el onclick
    if (isHome) {
      setTimeout(() => {
        let element = document.getElementById(section);
        window.scrollTo({
          top: element.getBoundingClientRect().top,
          behavior: "smooth",
        });
      }, 300);
    } else {
      window.location.href = "/#" + section;
    }
  }

  return (
    <div className="header-new-box">
      <div className="header-new">
        {/*<img src={logo} className='logo-new'/>*/}
      </div>
      <div className="header-menu-box">
        <div className="header-new-font">{/*NOSOTROS*/}</div>
        <div className="header-new-font">{/*PRODUCTOS*/}</div>
        <div className="header-new-font">{/*CONTACTO*/}</div>
      </div>
      <div className="navigation">
        <button id="sized" className="menu">
          <span></span>
        </button>
        <div id="myModal" className="modal-1">
          <div className="modal-1-content-new animated zoomIn">
            <div className="modal-1-header-new">
              <span className="close">×</span>
            </div>
            <div style={{ height: "100%" }}>
              <div className="modal-1-body nav">
                <ul>
                  <li>
                    <a
                      onClick={() => {
                        closeMenu("video-box");
                      }}
                      href="#"
                      className="text-a"
                    >
                      HOME
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        closeMenu("we-box");
                      }}
                      href="#"
                      className="text-a"
                    >
                      NOSOTROS
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        closeMenu("producto-box");
                      }}
                      href="#"
                      className="text-a"
                    >
                      PRODUCTOS
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        closeMenu("contacto-box");
                      }}
                      href="#"
                      className="text-a"
                    >
                      CONTACTO
                    </a>
                  </li>
                </ul>
              </div>
              <div className="modal-1-footer-new">
                <div className="box-cont">
                  <hr className="hr-menu" />
                  <div className="footer-new-font">
                    <div>Bogotá - Colombia</div>
                    <div>Transversal 22Bis # 61B - 67</div>
                    <div> 6015486676 - 6012804105 </div>
                    <div>vegaprom@hotmail.com</div>
                  </div>
                  <div className="wa-1">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://api.whatsapp.com/send?phone=573015653747&text=Hola. Deseo obtener más información.https://api.whatsapp.com/send?phone=573015653747&text=Hola.%20Deseo%20obtener%20m%C3%A1s%20informaci%C3%B3n."
                      className="whatsApp-const-2"
                    >
                      <FontAwesomeIcon icon={faWhatsapp} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderNew;
