import "./Contact.scss";
import pic from "../../assets/construction/background.jpg";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

function Contact() {
  let [formState, setFormState] = useState({});
  let [showError, setShowError] = useState(false);
  let [showSend, setShowSend] = useState(false);
  let { executeRecaptcha } = useGoogleReCaptcha();

  function handleChange(fieldName, value) {
    setFormState({ ...formState, [fieldName]: value });
  }

  // input solo numeros
  function handleChangeTel(fieldName, value) {
    value = value.replace(/\D/g, "");

    if (value.length > 10) {
      value = value.substring(0, 10);
    }
    setFormState({ ...formState, [fieldName]: value });
  }

  function showModal() {
    setShowSend(true);
  }

  function closeModal() {
    setShowSend(false);
  }

  const sendForm = async (e) => {
    e.preventDefault();

    if (!formState.nombre || !formState.telefono || !formState.colegio) {
      setShowError(true);
    } else if (formState.telefono.length < 10) {
      setShowError(true);
    } else {
      const token = await executeRecaptcha("setForm");

      await axios
        .post(`${process.env.REACT_APP_API_URL}/contactos`, {
          ...formState,
          token: token,
        })
        .then((response) => {
          setFormState({ nombre: "", telefono: "", colegio: "", mensaje: "" });
          setShowError(false);
          showModal();
        })
        .catch((error) => {
          alert("error");
        });
    }
  };
  return (
    <div
      className="contact-box"
      id="contacto-box"
      style={{ backgroundImage: `url(${pic})` }}
    >
      <form className="form-contact">
        <div className="contact-text">QUEREMOS CREAR ALGO LINDO PARA TI</div>
        <div className="second-text">
          Escríbenos para tener la mejor chaqueta prom
        </div>
        <input
          type="text"
          placeholder="Nombre"
          className="input-text"
          value={formState.nombre}
          onChange={(event) => {
            handleChange("nombre", event.target.value);
          }}
        />{" "}
        {showError && !formState.nombre && (
          <div className="field-text">Esta información es obligatoria</div>
        )}
        <input
          type="text"
          placeholder="Colegio"
          className="input-text"
          value={formState.colegio}
          onChange={(event) => {
            handleChange("colegio", event.target.value);
          }}
        />{" "}
        {showError && !formState.colegio && (
          <div className="field-text">Esta información es obligatoria</div>
        )}
        <input
          type="text"
          placeholder="Teléfono"
          className="input-text"
          value={formState.telefono}
          onChange={(event) => {
            handleChangeTel("telefono", event.target.value);
          }}
        />{" "}
        {showError &&
          (!formState.telefono || formState.telefono.length < 10) && (
            <div className="field-text">
              Escriba los 10 dígitos de su número de celular
            </div>
          )}
        <textarea
          className="text-area-contact"
          placeholder="Escríbenos tu mensaje:"
          value={formState.mensaje}
          onChange={(event) => {
            handleChange("mensaje", event.target.value);
          }}
        ></textarea>
        <div className="send-btn">
          <button className="send-text" onClick={sendForm}>
            ENVIAR
          </button>
        </div>
      </form>
      <Modal show={showSend} onHide={closeModal}>
        <Modal.Header closeButton className="modal-form">
          <Modal.Title>MUCHAS GRACIAS POR ESCRIBIRNOS</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-form-1">
          Tu mensaje ha sido enviado correctamente.
        </Modal.Body>
        <Modal.Footer className="modal-form">
          <Button
            variant="secondary"
            onClick={closeModal}
            className="modal-close"
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Contact;
