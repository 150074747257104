import "./Grid.scss";
import grid1 from "../../assets/1.webp";
import grid2 from "../../assets/2.webp";
import grid3 from "../../assets/7.webp";
import grid4 from "../../assets/8.webp";
import grid5 from "../../assets/20.jpg";
import grid6 from "../../assets/21.jpg";
import grid7 from "../../assets/22.webp";
import grid8 from "../../assets/15.webp";
function Grid() {
  return (
    <div className="grid-home">
      <div className="grid">
        <div
          className="grid-item1"
          style={{ backgroundImage: `url(${grid7})` }}
        ></div>
        <div
          className="grid-item2"
          style={{ backgroundImage: `url(${grid2})` }}
        ></div>
        <div
          className="grid-item3"
          style={{ backgroundImage: `url(${grid4})` }}
        ></div>
        <div
          className="grid-item4"
          style={{ backgroundImage: `url(${grid5})` }}
        ></div>
        <div
          className="grid-item5"
          style={{ backgroundImage: `url(${grid1})` }}
        ></div>
        <div
          className="grid-item6"
          style={{ backgroundImage: `url(${grid3})` }}
        ></div>
        <div
          className="grid-item7"
          style={{ backgroundImage: `url(${grid8})` }}
        ></div>
        <div
          className="grid-item8"
          style={{ backgroundImage: `url(${grid6})` }}
        ></div>
      </div>
    </div>
  );
}
export default Grid;
