import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Contacto from "./sections/contacto/Contacto";
import Orders from "./sections/orders/Orders";
import Producto from "./sections/producto/Producto";
import "react-image-gallery/styles/css/image-gallery.css";
import "./assets/shared-styles/reset.scss";
import HomeNew from "./newpage/home/Home-new";
import Construction from "./newpage/construction/Construction";
import { useEffect } from "react";
import Test from "./newpage/we/Test";
import Jackets from "./newpage/productos/Jackets";
import Sacos from "./newpage/productos/Sacos";
import Otros from "./newpage/productos/Otros";

function App() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  if (process.env.REACT_APP_MAINTENANCE && false) {
    return <Construction></Construction>;
  } else {
    return (
      <Router>
        {/*<Header></Header>*/}

        <Routes>
          <Route path="/chaquetas" element={<Jackets></Jackets>}></Route>
          <Route path="/sacos" element={<Sacos></Sacos>}></Route>
          <Route path="/otros" element={<Otros></Otros>}></Route>
          <Route path="/contacto" element={<Contacto></Contacto>}></Route>
          <Route
            path="/"
            element={
              <div className="home-container" id="home-box">
                <HomeNew></HomeNew>
              </div>
            }
          ></Route>
        </Routes>
        {/*<Footer></Footer>*/}
      </Router>
    );
  }
}

export default App;
