import "./Jackets.scss";
import chaq1 from "../../assets/productos/chaquetas/IMG_2864.jpg";
import chaq2 from "../../assets/productos/chaquetas/IMG_2866.jpg";
import chaq3 from "../../assets/productos/chaquetas/IMG_2862.jpg";
import chaq4 from "../../assets/productos/chaquetas/IMG_2934.jpg";
import chaq5 from "../../assets/productos/chaquetas/IMG_2936.jpg";
import chaq6 from "../../assets/productos/chaquetas/IMG_2944.jpg";
import chaq7 from "../../assets/productos/chaquetas/IMG_2945.jpg";
import chaq8 from "../../assets/productos/chaquetas/IMG_2950.jpg";
import chaq9 from "../../assets/productos/chaquetas/IMG_2951.jpg";
import chaq10 from "../../assets/productos/chaquetas/IMG_2955.jpg";
import chaq11 from "../../assets/productos/chaquetas/IMG_2957.jpg";
import chaq12 from "../../assets/productos/chaquetas/IMG_2963.jpg";
import chaq13 from "../../assets/productos/chaquetas/IMG_2965.jpg";
import chaq14 from "../../assets/productos/chaquetas/IMG_2973.jpg";
import chaq15 from "../../assets/productos/chaquetas/IMG_2975.jpg";
import chaq16 from "../../assets/productos/chaquetas/IMG_2979.jpg";
import chaq17 from "../../assets/productos/chaquetas/IMG_2983.jpg";
import chaq18 from "../../assets/productos/chaquetas/IMG_2989.jpg";
import chaq19 from "../../assets/productos/chaquetas/IMG_2991.jpg";
import chaq20 from "../../assets/productos/chaquetas/IMG_2997.jpg";
import chaq21 from "../../assets/productos/chaquetas/IMG_3000.jpg";
import chaq22 from "../../assets/productos/chaquetas/IMG_3003.jpg";
import chaq23 from "../../assets/productos/chaquetas/IMG_3007.jpg";
import chaq24 from "../../assets/productos/chaquetas/IMG_3012.jpg";
import chaq25 from "../../assets/productos/chaquetas/chaquetas2/IMG_3103.jpg";
import chaq26 from "../../assets/productos/chaquetas/chaquetas2/IMG_3215.jpg";
import chaq27 from "../../assets/productos/chaquetas/chaquetas2/IMG_3235.jpg";
import chaq28 from "../../assets/productos/chaquetas/chaquetas2/IMG_3237.jpg";
import chaq29 from "../../assets/productos/chaquetas/chaquetas2/IMG_3240.jpg";
import chaq30 from "../../assets/productos/chaquetas/chaquetas2/IMG_3241.jpg";
import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

function Jackets() {
  useEffect(() => {
    var chaquetas = document.getElementById("jacket");
    var chaquetasBox = document.getElementById("box-jacket");
    gsap.registerPlugin(ScrollTrigger);
    var timeline = gsap.timeline({
      scrollTrigger: {
        trigger: document.body,
        scrub: 0.6,
        start: "top top",
        end: () => {
          return window.innerHeight / 2;
        },
      },
    });
    timeline.fromTo(
      chaquetas,
      { fontSize: "10vw", duration: 1, top: "50vh", yPercent: -50 },
      {
        fontSize: "3vw",
        duration: 1,
        top: "0",
        yPercent: 0,
        paddingTop: "80px",
      },
      "1"
    );

    var pics1 = document.getElementById("jacket-trigger");
    var timeline2 = gsap.timeline({
      scrollTrigger: {
        trigger: pics1,
        scrub: 0.6,
        start: "top top",
        end: "bottom bottom",
      },
    });

    gsap.utils.toArray(".jacket-pin").forEach((pics) => {
      setTimeout(() => {
        var yPercent =
          100 -
          (pics.getBoundingClientRect().height * 100) /
            pics1.getBoundingClientRect().height;
        timeline2.fromTo(pics, { yPercent: 0 }, { yPercent: yPercent }, "l");
      }, 500);
    });
  }, []);

  // click photo modal

  const [show, setShow] = useState(false);
  const [image, setImage] = useState(null);
  const handleClose = () => {
    setShow(false);
    document.getElementById("root").style.filter = "";
  };
  const handleShow = (imagen) => {
    setImage(imagen);
    setShow(true);
    document.getElementById("root").style.filter = "blur(12px)";
  };
  function backHome() {
    window.location.href = "/#producto-box";
  }
  return (
    <div>
      <div className="jacket-menu">
        <div className="send-btn-1">
          <button className="send-text-1" onClick={backHome}>
            VOLVER ATRÁS
          </button>
        </div>
      </div>
      <div className="productos-pic" id="product">
        <div className="box-jacket" id="box-jacket">
          <h1 className="logo-title" id="jacket">
            CHAQUETAS
          </h1>
        </div>
        <div className="pic-container">
          <div className="pic-box-jacket" id="jacket-trigger">
            <img
              src={chaq1}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq1);
              }}
            />
            <img
              src={chaq2}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq2);
              }}
            />
            <img
              src={chaq3}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq3);
              }}
            />
            <img
              src={chaq4}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq4);
              }}
            />
            <img
              src={chaq5}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq5);
              }}
            />
            <img
              src={chaq6}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq6);
              }}
            />
            <img
              src={chaq7}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq7);
              }}
            />
            <img
              src={chaq8}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq8);
              }}
            />
            <img
              src={chaq25}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq25);
              }}
            />
            <img
              src={chaq26}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq26);
              }}
            />
          </div>
          <div className="pic-box-jacket pic-box-jacket-2 jacket-pin">
            <img
              src={chaq9}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq9);
              }}
            />
            <img
              src={chaq10}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq10);
              }}
            />
            <img
              src={chaq11}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq11);
              }}
            />
            <img
              src={chaq12}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq12);
              }}
            />
            <img
              src={chaq13}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq13);
              }}
            />
            <img
              src={chaq14}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq14);
              }}
            />
            <img
              src={chaq15}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq15);
              }}
            />
            <img
              src={chaq16}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq16);
              }}
            />
            <img
              src={chaq27}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq27);
              }}
            />
            <img
              src={chaq28}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq28);
              }}
            />
          </div>
          <div className="pic-box-jacket jacket-pin">
            <img
              src={chaq17}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq17);
              }}
            />
            <img
              src={chaq18}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq18);
              }}
            />
            <img
              src={chaq19}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq19);
              }}
            />
            <img
              src={chaq20}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq20);
              }}
            />
            <img
              src={chaq21}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq21);
              }}
            />
            <img
              src={chaq22}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq22);
              }}
            />
            <img
              src={chaq23}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq23);
              }}
            />
            <img
              src={chaq24}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq24);
              }}
            />
            <img
              src={chaq29}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq29);
              }}
            />
            <img
              src={chaq30}
              className="pic-jacket"
              onClick={() => {
                handleShow(chaq30);
              }}
            />
          </div>
        </div>
      </div>
      <div className="display-mobile-jacket">
        <div className="title-box-mobile">
          <h1 className="mobile-title-jacket">CHAQUETAS</h1>
        </div>
        <div className="pic-mobile-box">
          <img
            src={chaq1}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq1);
            }}
          />
          <img
            src={chaq2}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq2);
            }}
          />
          <img
            src={chaq3}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq3);
            }}
          />
          <img
            src={chaq4}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq4);
            }}
          />{" "}
          <img
            src={chaq5}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq5);
            }}
          />
          <img
            src={chaq6}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq6);
            }}
          />
          <img
            src={chaq7}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq7);
            }}
          />
          <img
            src={chaq8}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq8);
            }}
          />
          <img
            src={chaq9}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq9);
            }}
          />
          <img
            src={chaq10}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq10);
            }}
          />
          <img
            src={chaq11}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq11);
            }}
          />
          <img
            src={chaq12}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq12);
            }}
          />
          <img
            src={chaq13}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq13);
            }}
          />
          <img
            src={chaq14}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq14);
            }}
          />
          <img
            src={chaq15}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq15);
            }}
          />
          <img
            src={chaq16}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq16);
            }}
          />
          <img
            src={chaq17}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq17);
            }}
          />
          <img
            src={chaq18}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq18);
            }}
          />
          <img
            src={chaq19}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq19);
            }}
          />
          <img
            src={chaq20}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq20);
            }}
          />
          <img
            src={chaq21}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq21);
            }}
          />
          <img
            src={chaq22}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq22);
            }}
          />
          <img
            src={chaq23}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq23);
            }}
          />
          <img
            src={chaq24}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq24);
            }}
          />{" "}
          <img
            src={chaq25}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq25);
            }}
          />
          <img
            src={chaq26}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq26);
            }}
          />
          <img
            src={chaq27}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq27);
            }}
          />
          <img
            src={chaq28}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq28);
            }}
          />
          <img
            src={chaq29}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq29);
            }}
          />
          <img
            src={chaq30}
            className="pic-jacket"
            onClick={() => {
              handleShow(chaq30);
            }}
          />
        </div>
      </div>
      <Modal
        className="modal-jacket"
        size="xl"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Body className="modal-box-jacket">
          <img className="modal-pic" src={image} onClick={handleClose} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Jackets;
