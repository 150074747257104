import "./Footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <div>
      <div className="footer-home">
        <div className="footer-box">
          <div className="wa-1">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://api.whatsapp.com/send?phone=573015653747&text=Hola. Deseo obtener más información.https://api.whatsapp.com/send?phone=573015653747&text=Hola.%20Deseo%20obtener%20m%C3%A1s%20informaci%C3%B3n."
              className="whatsApp-const-1"
            >
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
          </div>
          <div>Transversal 22Bis # 61B - 67 </div>
          <div> 3245166551 </div>
          <b>vegaprom@hotmail.com</b>
          <div>
            <strong>Bogotá - Colombia</strong>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
