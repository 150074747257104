import "./Otros.scss";
import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import HeaderNew from "../header/Header-new";
import otr1 from "../../assets/productos/otros/IMG_3122.jpg";
import otr2 from "../../assets/productos/otros/IMG_3117.jpg";
import otr3 from "../../assets/productos/otros/IMG_3125.jpg";
import otr4 from "../../assets/productos/otros/IMG_3130.jpg";
import otr5 from "../../assets/productos/otros/IMG_3136.jpg";
import otr6 from "../../assets/productos/otros/IMG_3138.jpg";
import otr7 from "../../assets/productos/otros/IMG_3146.jpg";
import otr8 from "../../assets/productos/otros/IMG_3150.jpg";
import otr9 from "../../assets/productos/otros/IMG_3210.jpg";
import otr10 from "../../assets/productos/otros/IMG_3225.jpg";
import otr11 from "../../assets/productos/otros/IMG_3260.jpg";
import otr12 from "../../assets/productos/otros/IMG_3302.jpg";
import otr13 from "../../assets/productos/otros/IMG_3308.jpg";
import otr14 from "../../assets/productos/otros/IMG_3315.jpg";
import otr15 from "../../assets/productos/otros/IMG_3316.jpg";
import otr16 from "../../assets/productos/otros/IMG_3318.jpg";
import otr17 from "../../assets/productos/otros/IMG_3320.jpg";
import otr18 from "../../assets/productos/otros/IMG_3322.jpg";
import otr19 from "../../assets/productos/otros/IMG_3325.jpg";
import otr20 from "../../assets/productos/otros/IMG_3329.jpg";
import otr21 from "../../assets/productos/otros/IMG_3332.jpg";
import otr22 from "../../assets/productos/otros/IMG_3334.jpg";
import otr23 from "../../assets/productos/otros/IMG_3335.jpg";
import otr24 from "../../assets/productos/otros/IMG_3337.jpg";
import otr25 from "../../assets/productos/otros/IMG_3340.jpg";
import otr26 from "../../assets/productos/otros/IMG_3342.jpg";
import otr27 from "../../assets/productos/otros/IMG_3344.jpg";
import otr28 from "../../assets/productos/otros/IMG_3345.jpg";
import otr29 from "../../assets/productos/otros/IMG_3351.jpg";
import otr30 from "../../assets/productos/otros/IMG_3354.jpg";
import otr31 from "../../assets/productos/otros/IMG_3356.jpg";
import otr32 from "../../assets/productos/otros/IMG_3357.jpg";
import otr33 from "../../assets/productos/otros/IMG_3360.jpg";
import otr34 from "../../assets/productos/otros/IMG_3361.jpg";
import otr35 from "../../assets/productos/otros/IMG_3363.jpg";
import otr36 from "../../assets/productos/otros/IMG_3366.jpg";
import otr37 from "../../assets/productos/otros/IMG_3371.jpg";
import otr38 from "../../assets/productos/otros/IMG_3374.jpg";
import otr39 from "../../assets/productos/otros/IMG_3377.jpg";

function Otros() {
  useEffect(() => {
    var otros = document.getElementById("otros");
    var otrosBox = document.getElementById("box-otros");
    gsap.registerPlugin(ScrollTrigger);
    var timeline = gsap.timeline({
      scrollTrigger: {
        trigger: document.body,
        scrub: 0.6,
        start: "top top",
        end: () => {
          return window.innerHeight / 2;
        },
      },
    });
    timeline.fromTo(
      otros,
      { fontSize: "10vw", duration: 1, top: "50vh", yPercent: -50 },
      {
        fontSize: "3vw",
        duration: 1,
        top: "0",
        yPercent: 0,
        paddingTop: "80px",
      },
      "1"
    );

    var pics1 = document.getElementById("otros-trigger");
    var timeline2 = gsap.timeline({
      scrollTrigger: {
        trigger: pics1,
        scrub: 0.6,
        start: "top top",
        end: "bottom bottom",
      },
    });

    gsap.utils.toArray(".otros-pin").forEach((pics) => {
      setTimeout(() => {
        var yPercent =
          100 -
          (pics.getBoundingClientRect().height * 100) /
            pics1.getBoundingClientRect().height;
        timeline2.fromTo(pics, { yPercent: 0 }, { yPercent: yPercent }, "l");
      }, 500);
    });
  }, []);

  // click photo modal

  const [show, setShow] = useState(false);
  const [image, setImage] = useState(null);
  const handleClose = () => {
    setShow(false);
    document.getElementById("root").style.filter = "";
  };
  const handleShow = (imagen) => {
    setImage(imagen);
    setShow(true);
    document.getElementById("root").style.filter = "blur(12px)";
  };
  function backHome() {
    window.location.href = "/#producto-box";
  }
  return (
    <div>
      <div className="otros-menu">
        <div className="send-btn-1">
          <button className="send-text-1" onClick={backHome}>
            VOLVER ATRÁS
          </button>
        </div>
      </div>

      <div className="productos-pic" id="product">
        <div className="box-otros" id="box-otros">
          <h1 className="logo-title" id="otros">
            CAMISETAS / OTROS
          </h1>
        </div>
        <div className="pic-container">
          <div className="pic-box-otros" id="otros-trigger">
            <img
              src={otr1}
              className="pic-otros"
              onClick={() => {
                handleShow(otr1);
              }}
            />
            <img
              src={otr2}
              className="pic-otros"
              onClick={() => {
                handleShow(otr2);
              }}
            />
            <img
              src={otr3}
              className="pic-otros"
              onClick={() => {
                handleShow(otr3);
              }}
            />
            <img
              src={otr4}
              className="pic-otros"
              onClick={() => {
                handleShow(otr4);
              }}
            />
            <img
              src={otr5}
              className="pic-otros"
              onClick={() => {
                handleShow(otr5);
              }}
            />
            <img
              src={otr6}
              className="pic-otros"
              onClick={() => {
                handleShow(otr6);
              }}
            />
            <img
              src={otr7}
              className="pic-otros"
              onClick={() => {
                handleShow(otr7);
              }}
            />
            <img
              src={otr8}
              className="pic-otros"
              onClick={() => {
                handleShow(otr8);
              }}
            />
            <img
              src={otr9}
              className="pic-otros"
              onClick={() => {
                handleShow(otr9);
              }}
            />
            <img
              src={otr10}
              className="pic-otros"
              onClick={() => {
                handleShow(otr10);
              }}
            />
            <img
              src={otr11}
              className="pic-otros"
              onClick={() => {
                handleShow(otr11);
              }}
            />
            <img
              src={otr12}
              className="pic-otros"
              onClick={() => {
                handleShow(otr12);
              }}
            />
            <img
              src={otr13}
              className="pic-otros"
              onClick={() => {
                handleShow(otr13);
              }}
            />
          </div>
          <div className="pic-box-otros pic-box-otros-2 otros-pin">
            <img
              src={otr15}
              className="pic-otros"
              onClick={() => {
                handleShow(otr15);
              }}
            />
            <img
              src={otr16}
              className="pic-otros"
              onClick={() => {
                handleShow(otr16);
              }}
            />
            <img
              src={otr17}
              className="pic-otros"
              onClick={() => {
                handleShow(otr17);
              }}
            />
            <img
              src={otr18}
              className="pic-otros"
              onClick={() => {
                handleShow(otr18);
              }}
            />
            <img
              src={otr19}
              className="pic-otros"
              onClick={() => {
                handleShow(otr19);
              }}
            />
            <img
              src={otr20}
              className="pic-otros"
              onClick={() => {
                handleShow(otr20);
              }}
            />
            <img
              src={otr21}
              className="pic-otros"
              onClick={() => {
                handleShow(otr21);
              }}
            />
            <img
              src={otr22}
              className="pic-otros"
              onClick={() => {
                handleShow(otr22);
              }}
            />
            <img
              src={otr23}
              className="pic-otros"
              onClick={() => {
                handleShow(otr23);
              }}
            />
            <img
              src={otr24}
              className="pic-otros"
              onClick={() => {
                handleShow(otr24);
              }}
            />
            <img
              src={otr25}
              className="pic-otros"
              onClick={() => {
                handleShow(otr25);
              }}
            />
            <img
              src={otr26}
              className="pic-otros"
              onClick={() => {
                handleShow(otr26);
              }}
            />
            <img
              src={otr27}
              className="pic-otros"
              onClick={() => {
                handleShow(otr27);
              }}
            />
          </div>
          <div className="pic-box-otros otros-pin">
            <img
              src={otr28}
              className="pic-otros"
              onClick={() => {
                handleShow(otr28);
              }}
            />
            <img
              src={otr29}
              className="pic-otros"
              onClick={() => {
                handleShow(otr29);
              }}
            />
            <img
              src={otr30}
              className="pic-otros"
              onClick={() => {
                handleShow(otr30);
              }}
            />
            <img
              src={otr31}
              className="pic-otros"
              onClick={() => {
                handleShow(otr31);
              }}
            />
            <img
              src={otr32}
              className="pic-otros"
              onClick={() => {
                handleShow(otr32);
              }}
            />
            <img
              src={otr33}
              className="pic-otros"
              onClick={() => {
                handleShow(otr33);
              }}
            />
            <img
              src={otr34}
              className="pic-otros"
              onClick={() => {
                handleShow(otr34);
              }}
            />
            <img
              src={otr35}
              className="pic-otros"
              onClick={() => {
                handleShow(otr35);
              }}
            />
            <img
              src={otr36}
              className="pic-otros"
              onClick={() => {
                handleShow(otr36);
              }}
            />
            <img
              src={otr37}
              className="pic-otros"
              onClick={() => {
                handleShow(otr37);
              }}
            />
            <img
              src={otr38}
              className="pic-otros"
              onClick={() => {
                handleShow(otr38);
              }}
            />
            <img
              src={otr39}
              className="pic-otros"
              onClick={() => {
                handleShow(otr39);
              }}
            />
            <img
              src={otr14}
              className="pic-otros"
              onClick={() => {
                handleShow(otr14);
              }}
            />
          </div>
        </div>
      </div>

      {/*mobile*/}

      <div className="display-mobile-otros">
        <div className="title-box-mobile">
          <h1 className="mobile-title-otros">CAMISETAS</h1>
        </div>
        <div className="pic-mobile-box">
          <img
            src={otr1}
            className="pic-otros"
            onClick={() => {
              handleShow(otr1);
            }}
          />
          <img
            src={otr2}
            className="pic-otros"
            onClick={() => {
              handleShow(otr2);
            }}
          />
          <img
            src={otr3}
            className="pic-otros"
            onClick={() => {
              handleShow(otr3);
            }}
          />
          <img
            src={otr4}
            className="pic-otros"
            onClick={() => {
              handleShow(otr4);
            }}
          />
          <img
            src={otr5}
            className="pic-otros"
            onClick={() => {
              handleShow(otr5);
            }}
          />
          <img
            src={otr6}
            className="pic-otros"
            onClick={() => {
              handleShow(otr6);
            }}
          />
          <img
            src={otr7}
            className="pic-otros"
            onClick={() => {
              handleShow(otr7);
            }}
          />
          <img
            src={otr8}
            className="pic-otros"
            onClick={() => {
              handleShow(otr8);
            }}
          />
          <img
            src={otr9}
            className="pic-otros"
            onClick={() => {
              handleShow(otr9);
            }}
          />
          <img
            src={otr10}
            className="pic-otros"
            onClick={() => {
              handleShow(otr10);
            }}
          />
          <img
            src={otr11}
            className="pic-otros"
            onClick={() => {
              handleShow(otr11);
            }}
          />
          <img
            src={otr12}
            className="pic-otros"
            onClick={() => {
              handleShow(otr12);
            }}
          />
          <img
            src={otr13}
            className="pic-otros"
            onClick={() => {
              handleShow(otr13);
            }}
          />
          <img
            src={otr14}
            className="pic-otros"
            onClick={() => {
              handleShow(otr14);
            }}
          />
          <img
            src={otr15}
            className="pic-otros"
            onClick={() => {
              handleShow(otr15);
            }}
          />
          <img
            src={otr16}
            className="pic-otros"
            onClick={() => {
              handleShow(otr16);
            }}
          />
          <img
            src={otr17}
            className="pic-otros"
            onClick={() => {
              handleShow(otr17);
            }}
          />
          <img
            src={otr18}
            className="pic-otros"
            onClick={() => {
              handleShow(otr18);
            }}
          />
          <img
            src={otr19}
            className="pic-otros"
            onClick={() => {
              handleShow(otr19);
            }}
          />
          <img
            src={otr20}
            className="pic-otros"
            onClick={() => {
              handleShow(otr20);
            }}
          />
          <img
            src={otr21}
            className="pic-otros"
            onClick={() => {
              handleShow(otr21);
            }}
          />
          <img
            src={otr22}
            className="pic-otros"
            onClick={() => {
              handleShow(otr22);
            }}
          />
          <img
            src={otr23}
            className="pic-otros"
            onClick={() => {
              handleShow(otr23);
            }}
          />
          <img
            src={otr24}
            className="pic-otros"
            onClick={() => {
              handleShow(otr24);
            }}
          />
          <img
            src={otr25}
            className="pic-otros"
            onClick={() => {
              handleShow(otr25);
            }}
          />
          <img
            src={otr26}
            className="pic-otros"
            onClick={() => {
              handleShow(otr26);
            }}
          />
          <img
            src={otr27}
            className="pic-otros"
            onClick={() => {
              handleShow(otr27);
            }}
          />
          <img
            src={otr28}
            className="pic-otros"
            onClick={() => {
              handleShow(otr28);
            }}
          />
          <img
            src={otr29}
            className="pic-otros"
            onClick={() => {
              handleShow(otr29);
            }}
          />
          <img
            src={otr30}
            className="pic-otros"
            onClick={() => {
              handleShow(otr30);
            }}
          />
          <img
            src={otr31}
            className="pic-otros"
            onClick={() => {
              handleShow(otr31);
            }}
          />
          <img
            src={otr32}
            className="pic-otros"
            onClick={() => {
              handleShow(otr32);
            }}
          />
          <img
            src={otr33}
            className="pic-otros"
            onClick={() => {
              handleShow(otr33);
            }}
          />
          <img
            src={otr34}
            className="pic-otros"
            onClick={() => {
              handleShow(otr34);
            }}
          />
          <img
            src={otr35}
            className="pic-otros"
            onClick={() => {
              handleShow(otr35);
            }}
          />
          <img
            src={otr36}
            className="pic-otros"
            onClick={() => {
              handleShow(otr36);
            }}
          />
          <img
            src={otr37}
            className="pic-otros"
            onClick={() => {
              handleShow(otr37);
            }}
          />
          <img
            src={otr38}
            className="pic-otros"
            onClick={() => {
              handleShow(otr38);
            }}
          />
          <img
            src={otr39}
            className="pic-otros"
            onClick={() => {
              handleShow(otr39);
            }}
          />
        </div>
      </div>

      <Modal
        className="modal-otros"
        size="xl"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Body className="modal-box-otros">
          <img className="modal-pic" src={image} onClick={handleClose} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Otros;
