import "./Menu-nav.scss";
import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function MenuNav() {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    let weBox = document.getElementById("we-box");
    let menuBox = document.getElementById("menu-box");
    let timeline = gsap.timeline({
      scrollTrigger: {
        trigger: weBox,
        start: "top 100",
        markers: false,
        toggleActions: "play none none reverse",
      },
    });
    timeline.to(menuBox, { opacity: 1, duration: 0.5 }, "text");
  }, []);

  return (
    <div>
      <ul className="menu-box" id="menu-box">
        <li className="li-nav">
          <a href="#video-box">HOME</a>
        </li>
        <li className="li-nav">
          {" "}
          <a href="#we-box">NOSOTROS</a>
        </li>
        <li className="li-nav">
          {" "}
          <a href="#producto-box">PRODUCTOS</a>
        </li>
        <li className="li-nav">
          {" "}
          <a href="#contacto-box">CONTACTO</a>
        </li>
      </ul>
    </div>
  );
}

export default MenuNav;
