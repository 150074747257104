import "./Test.scss";
import { useEffect } from "react";
import { gsap } from "gsap";
import { ReactComponent as Logo } from "../../assets/intro/vega.svg";

function Test() {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const puppeteer = urlParams.get("puppeteer");
    let isPuppeteer = !!puppeteer;

    let fragment = window.location.hash;
    const colorBox = document.getElementById("color-box");
    if (!fragment && !isPuppeteer) {
      const timeLine = gsap.timeline();
      const color2 = document.getElementById("color-2");

      const homeSlide = document.getElementById("home-box");
      timeLine.to(color2, { left: 0, duration: 2, delay: 0.5 });
      timeLine.to(colorBox, { left: "100%", duration: 0.5 }, "slide");
      timeLine.to(homeSlide, { left: "0", duration: 0.5 }, "slide");
      timeLine.then(() => {
        colorBox.remove();
      });
    } else {
      document.getElementById("home-box").style.left = 0;
      colorBox.remove();
      setTimeout(() => {
        let element = document.getElementById(fragment.replace("#", ""));
        window.scrollTo({
          top: element.getBoundingClientRect().top,
          behavior: "smooth",
        });
      }, 300);
    }
  }, []);

  return (
    <div className="test-logo">
      <Logo id="logo"></Logo>
      <div className="color-box" id="color-box">
        <div className="color-text" id="color-text">
          <div className="color-1"></div>
          <div className="color-2" id="color-2"></div>
        </div>
      </div>
    </div>
  );
}

export default Test;
