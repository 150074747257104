import "./Video-new.scss";
import HeaderNew from "../../header/Header-new";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesDown } from "@fortawesome/free-solid-svg-icons";
import video1 from "../../../assets/video/heart-svgrepo-com.svg";
import video2 from "../../../assets/video/sewing-machine-svgrepo-com.svg";
import video3 from "../../../assets/video/varsity-jacket-svgrepo-com.svg";
import video5 from "../../../assets/video/spades-svgrepo-com.svg";
import video6 from "../../../assets/video/smile-svgrepo-com.svg";
import video7 from "../../../assets/video/star-svgrepo-com.svg";
import video8 from "../../../assets/video/hoodie-svgrepo-com.svg";
import { useEffect, useRef } from "react";

function VideoNew() {
  return (
    <div>
      <div className="video-box" id="video-box">
        <HeaderNew isHome={true}></HeaderNew>
        <div className="home-new-container">
          <div>
            <div className="home-new-font">
              <span>T</span>
              <span className="animation-font">
                <span className="font-fx-1">Ú</span>
                <span className="font-fx">
                  <img src={video5} className="video-img-1" />
                </span>
              </span>
              &nbsp;
              <span></span>
              <span>E</span>
              <span>R</span>
              <span className="animation-font">
                <span className="font-fx-2">E</span>
                <span className="font-fx-a">
                  <img src={video6} className="video-img-1" />
                </span>
              </span>
              <span>S </span>
            </div>
            <div className="home-new-font">
              <span>N</span>
              <span>U</span>
              <span className="animation-font">
                <span className="font-fx-3">E</span>
                <span className="font-fx-b">
                  <img src={video8} className="video-img-1" />
                </span>
              </span>
              <span>S</span>
              <span>T</span>
              <span>R</span>
              <span className="animation-font">
                <span className="font-fx-1">A</span>
                <span className="font-fx">
                  <img src={video7} className="video-img-1" />
                </span>
              </span>
            </div>
            <div className="home-new-font">
              <span>I</span>
              <span className="animation-font">
                <span className="font-fx-1">N</span>
                <span className="font-fx">
                  <img src={video2} className="video-img-1" />
                </span>
              </span>
              <span>S</span>
              <span>P</span>
              <span>I</span>
              <span className="animation-font">
                <span className="font-fx-2">R</span>
                <span className="font-fx-a">
                  <img src={video3} className="video-img-1" />
                </span>
              </span>
              <span>A</span>
              <span>C</span>
              <span>I</span>
              <span className="animation-font">
                <span className="font-fx-3">Ó</span>
                <span className="font-fx-b">
                  <img src={video1} className="video-img-1" />
                </span>
              </span>
              <span>N</span>
            </div>
          </div>
        </div>
        <div className="arrow-new" id="arrow-new">
          <a href="#we-box">
            {" "}
            <FontAwesomeIcon icon={faAnglesDown} className="arrow-page" />{" "}
          </a>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: `
                  <video
          class="video-new"
          autoPlay
          loop
          muted
          playsinline
        >
          <source src="assets/video/Fabric - 115793.mp4" type="video/mp4" />
        </video>
          `,
          }}
        ></div>
      </div>
    </div>
  );
}

export default VideoNew;
