import React from "react";
import './Producto.css'
import hoodie from './../../assets/10.jpg'
import 'react-multi-carousel/lib/styles.css';
import ImageGallery from 'react-image-gallery';

function Producto() {



    const images = [
        {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-116.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-116.jpg',
           
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-082+copia.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-082+copia.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-095.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-095.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-128.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-128.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-139.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-139.jpg',
             
             
        },
        {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-143.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-143.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-144.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-144.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-168.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-168.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-175+copia.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-175+copia.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-188+copia.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-188+copia.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-196.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-196.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-202.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-202.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-205.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-205.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-208.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-208.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-209.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-209.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-221+copia.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-221+copia.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-229.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-229.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-237+copia.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-237+copia.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-238+copia.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-238+copia.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-243+copia.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-243+copia.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-248.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-248.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-251.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-251.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-261.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-261.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-269.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-269.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-271.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-271.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-274+copia.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-274+copia.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-354+copia.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-354+copia.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-388.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-388.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-422.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-422.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-427.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-427.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-434.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-434.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-441.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-441.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-443.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-443.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-449.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-449.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-456.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-456.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-458.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-458.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-468.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/chaquetas-468.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/buso1-2.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/buso1-2.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/buso2-2.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/buso2-2.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/camisa2-1.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/camisa2-1.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/camisa2-2.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/camisa2-2.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/camisa3.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/camisa3.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/camisa4.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/camisa4.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/camise6.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/camise6.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/camiseta1.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/camiseta1.jpg',
             
             
        }, {
            original: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/camiseta5.jpg',
            thumbnail: 'https://vegaprom.s3.us-east-2.amazonaws.com/Productos/camiseta5.jpg',
             
             
        },


    ]


    return (
        <div className='pic-gallery'>
            <div><img alt="Hoodie" className='producto-pic' src={hoodie}/></div>

            <ImageGallery items={images}/>
        </div>
    )
}

export default Producto
