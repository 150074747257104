import "./We.scss";
import portada from "./../../assets/3.jpg";

function We() {
  return (
    <div>
      <div className="we-new-container">
        <div
          className="we-div-box"
          id="we-box"
          style={{ backgroundImage: `url(${portada})` }}
        >
          <div className="we-new">
            <div className="title-box">
              <h1 className="we-new-title">¿QUIÉNES SOMOS?</h1>
            </div>
            <div className="text-box">
              <div>
                <p className="we-new-font">
                  Somos una empresa de confecciones con más de 20 años de
                  experiencia en el mercado, dedicados a la fabricación de
                  productos prom en Colombia. Producimos chaquetas, sacos,
                  camisetas y accesorios como maletas, cachuchas y demás
                  artículos, para los colegios más importantes del país.
                  Contamos con nuestra propia infraestructura para ofrecer
                  productos de excelente calidad, satisfaciendo las necesidades
                  de nuestros clientes y brindando siempre un servicio de
                  excelencia y cumplimiento.
                </p>
              </div>
              <div>
                <p className="we-new-font">
                  Trabajamos con los principales texileros del país, ofreciendo
                  los mejores insumos para la elaboración de los productos prom.
                  Estamos siempre a la vanguardia de las nuevas tendencias,
                  innovando constantemente y preparándonos cada día para dar un
                  asesoramiento personalizado a nuestros clientes en todos los
                  procesos de elaboración de los productos. Contamos con nuestra
                  propia maquinaria con el fin de ofrecer una calidad óptima en
                  nuestros productos, satisfaciendo las necesidades de un
                  mercado exigente.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default We;
