import "./Home-new.scss";
import We from "../we/We";
import Test from "../we/Test";
import VideoNew from "./video/Video-new";
import Contact from "../contact/Contact";
import ParallaxWeb from "../parallax/Parallax-web";
import MenuNav from "../menu-nav/Menu-nav";
import WhyNew from "../why/Why-new";
import Grid from "../productos/Grid";
import Footer from "../footer/Footer";
import Products from "../products-new/Products";

function HomeNew() {
  return (
    <div>
      <VideoNew></VideoNew>
      <MenuNav></MenuNav>
      <We></We>
      <Grid></Grid>
      <WhyNew></WhyNew>
      <Products></Products>
      <Contact></Contact> <Test></Test>
      <Footer></Footer>
    </div>
  );
}
export default HomeNew;
