import "./Products.scss";
import p1 from "../../assets/productos/portada/IMG_9354.png";
import p2 from "../../assets/productos/portada/IMG_9351.png";
import p3 from "../../assets/productos/portada/IMG_9357.png";
import { useNavigate } from "react-router-dom";

function Products() {
  var navigate = useNavigate();

  return (
    <div
      id="producto-box"
      className="producto-box"
      style={{ backgroundImage: `url(${p2})` }}
    >
      <div className="products-title">NUESTROS PRODUCTOS</div>
      <div className="products-main">
        <div
          className="pic-products"
          // style={{ backgroundImage: `url(${p1})` }}
          onClick={() => {
            window.location.href = "/chaquetas";
          }}
        >
          <div className="font-products">CHAQUETAS</div>
        </div>
        <div
          className="pic-products"
          // style={{ backgroundImage: `url(${p2})` }}
          onClick={() => {
            window.location.href = "/sacos";
          }}
        >
          {" "}
          <div className="font-products">SACOS</div>
        </div>
        <div
          className="pic-products"
          // style={{ backgroundImage: `url(${p3})` }}
          onClick={() => {
            window.location.href = "/otros";
          }}
        >
          <div className="font-products">CAMISETAS</div>
        </div>
      </div>
    </div>
  );
}

export default Products;
