import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { StrictMode } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <GoogleReCaptchaProvider
    reCaptchaKey="6LeVvh8kAAAAABPvs4tu8ho0ch1gL0ZWxdTAJaav"
    language="[optional_language]"
  >
    <App />
  </GoogleReCaptchaProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
